<template>
  <div class="centralcontainer" >
    <h3>YOU CAN FIND US HERE...</h3>
    <h3>Email: info@ifinyela.com</h3>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <a href="https://www.instagram.com/ifinyela/" class="fa fa-instagram"></a>
  </div>
</template>

<script>
 
export default {
  name: 'contact',

  created() {
  // this.$eventHub.$emit('eventimageurl', '');
   },


}
</script>

<style lang="scss" scoped>
  @import "~@/styles/commonstyle.scss";
 
  </style>
